<template>
    <label :for="`radio-${option.descricao}-${option.id}`">
        <input type="radio" :value="option.id" :id="`radio-${option.descricao}-${option.id}`" @change="$parent.$emit('input', $event.target.value)"
            name="radio-input" />
        {{ option.descricao }}
    </label>
</template>

<script>
export default {
    name: 'RadioItem',
    props: {
        option: {
            required: true,
        },
    },
};
</script>

<style lang="scss" scoped>
label {
    cursor: pointer;

    input[type="radio"] {
        cursor: pointer;
        margin: 0 3px 0 0;
        accent-color: $cinza-1;
    }
}
</style>