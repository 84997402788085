import axios from 'axios';
import store from '@/store';
import { _rotasAPI } from '@/services/_caminhosApi';
import { tokenExpirado } from '@/utils/tokenExpirado';

export function listarSacOcorrencias(data) {
    return new Promise((resolve) => {
        const token = localStorage.getItem('token');
        store.commit('SET_ADICIONAR_LOADING');
        axios
            .post(_rotasAPI().listarSacOcorrencias, data, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
            .then((resp) => {
                resolve(resp);
            })
            .catch((erro) => {
                tokenExpirado(erro, true);
            })
            .finally(() => store.commit('SET_REMOVER_LOADING'));
    });
}

export function listarDetalhesOcorrenciaId(id, pagina, qtdPagina) {
    return new Promise((resolve) => {
        const token = localStorage.getItem('token');
        store.commit('SET_ADICIONAR_LOADING');
        axios
            .get(`${_rotasAPI().listarDetalhesOcorrenciaId}/${id}/${pagina}/${qtdPagina}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
            .then((resp) => {
                resolve(resp);
            })
            .catch((error) => {
                tokenExpirado(error, true);
            })
            .finally(() => store.commit('SET_REMOVER_LOADING'));
    });
}

export function atualizarDetalhesOcorrencia(id, data) {
    return new Promise((resolve) => {
        const token = localStorage.getItem('token');
        store.commit('SET_ADICIONAR_LOADING');
        axios
            .put(`${_rotasAPI().atualizarDetalhesOcorrencia}/${id}`, data, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
            .then((res, resp) => {
                resolve(resp);
                store.commit("SET_MODAL", {
                    ativado: true,
                    mensagem: res.data.mensagem,
                    tipo: "sucesso",
                })
            })
            .catch((erro) => {
                tokenExpirado(erro, true);
            })
            .finally(() =>
                store.commit('SET_REMOVER_LOADING'),
            );
    });
}

export function cadastrarMensagemOcorrencia(data) {
    return new Promise((resolve) => {
        const token = localStorage.getItem('token');
        store.commit('SET_ADICIONAR_LOADING');
        axios
            .post(_rotasAPI().cadastrarMensagemOcorrencia, data, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
            .then((res, resp) => {
                resolve(resp);
                store.commit("SET_MODAL", {
                    ativado: true,
                    mensagem: res.data.mensagem,
                    tipo: "sucesso",
                })
            })
            .catch((erro) => {
                tokenExpirado(erro, erro.response.data);
            })
            .finally(() =>
                store.commit('SET_REMOVER_LOADING'),
            );
    });
}

export function listarSacMensagemDestinatario() {
    return new Promise(resolve => {
        const token = localStorage.getItem("token");
        store.commit("SET_ADICIONAR_LOADING");
        axios
            .get(_rotasAPI().listarSacMensagemDestinatario, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            })
            .then(resp => {
                resolve(resp);
            })
            .catch(erro => {
                tokenExpirado(erro, true);
            })
            .finally(() => store.commit("SET_REMOVER_LOADING"));
    });
}

export function listarSacStatus() {
    return new Promise(resolve => {
        const token = localStorage.getItem("token");
        store.commit("SET_ADICIONAR_LOADING");
        axios
            .get(_rotasAPI().listarSacStatus, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            })
            .then(resp => {
                resolve(resp);
            })
            .catch(erro => {
                tokenExpirado(erro, true);
            })
            .finally(() => store.commit("SET_REMOVER_LOADING"));
    });
}

export function listarSacAssunto() {
    return new Promise(resolve => {
        const token = localStorage.getItem("token");
        store.commit("SET_ADICIONAR_LOADING");
        axios
            .get(_rotasAPI().listarSacAssunto, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            })
            .then(resp => {
                resolve(resp);
            })
            .catch(erro => {
                tokenExpirado(erro, true);
            })
            .finally(() => store.commit("SET_REMOVER_LOADING"));
    });
}

export function listarSacMeioDeContato() {
    return new Promise(resolve => {
        const token = localStorage.getItem("token");
        store.commit("SET_ADICIONAR_LOADING");
        axios
            .get(_rotasAPI().listarSacMeioDeContato, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            })
            .then(resp => {
                resolve(resp);
            })
            .catch(erro => {
                tokenExpirado(erro, true);
            })
            .finally(() => store.commit("SET_REMOVER_LOADING"));
    });
}

export function cadastrarOcorrencia(data) {
    return new Promise(resolve => {
        const token = localStorage.getItem("token");
        store.commit("SET_ADICIONAR_LOADING");
        axios
            .post(_rotasAPI().cadastrarOcorrencia, data, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            })
            .then((res, resp) => {
                resolve(resp);
                store.commit("SET_MODAL", {
                    ativado: true,
                    mensagem: res.data.mensagem,
                    tipo: "sucesso",
                })
            })
            .catch(erro => {
                tokenExpirado(erro, erro.response.data);
            })
            .finally(() => store.commit("SET_REMOVER_LOADING"));
    });
}