<template>
    <div class="radio-group">
        <RadioItem v-for="option in options" :option="option" :key="option.id" />
    </div>
</template>

<script>
import RadioItem from "./RadioItem.vue";

export default {
    name: "GrpRadio",
    components: {
        RadioItem
    },
    props: {
        options: { // Array de objetos com keys { id e texto } o retorno deste componente sempre será o id
            required: true,
            type: Array,
        },
    },
};
</script>

<style scoped>
.radio-group {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
}
</style>