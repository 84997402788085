<template>
    <div>
        <div class="d-md-flex">
            <div class="formulario-item">
                <label for="status" class="formulario-item-label">Status</label>
                <multiselect :class="{ 'invalid-field-multiselect': verificarSeHaItem('statusId', this.erros, 'field') }"
                    :disabled="perfilPainel == 4" v-model="dadosDropdowns.statusId" label="descricao" track-by="id"
                    :options="status" :searchable="false" :multiple="false" placeholder="Selecione"
                    select-label="Selecionar" deselect-label="Remover" selected-label="Selecionado" :allow-empty="true" />
                <p class="input-erro-mensagem" v-if="erros.length && verificarSeHaItem('statusId', this.erros, 'field')">
                    {{ verificarItemArray('statusId', this.erros, 'field').message }}
                </p>
            </div>

            <div class="formulario-item">
                <label for="assuntoId" class="formulario-item-label">Assunto</label>
                <multiselect :class="{ 'invalid-field-multiselect': verificarSeHaItem('assuntoId', this.erros, 'field') }"
                    :disabled="perfilPainel == 4" v-model="dadosDropdowns.assuntoId" label="descricao" track-by="id"
                    :options="assunto" :searchable="false" :multiple="false" placeholder="Selecione"
                    select-label="Selecionar" deselect-label="Remover" :allow-empty="true" selected-label="Selecionado" />
                <p class="input-erro-mensagem" v-if="erros.length && verificarSeHaItem('assuntoId', this.erros, 'field')">
                    {{ verificarItemArray('assuntoId', this.erros, 'field').message }}
                </p>
            </div>

            <div class="formulario-item">
                <label for="meioDeContato" class="formulario-item-label">Meio de contato</label>
                <multiselect
                    :class="{ 'invalid-field-multiselect': verificarSeHaItem('meioDeContatoId', this.erros, 'field') }"
                    :disabled="perfilPainel == 4" v-model="dadosDropdowns.meioDeContatoId" label="descricao" track-by="id"
                    :options="meioDeContato" :searchable="false" :multiple="false" placeholder="Selecione"
                    select-label="Selecionar" deselect-label="Remover" selected-label="Selecionado" :allow-empty="true" />
                <p class="input-erro-mensagem"
                    v-if="erros.length && verificarSeHaItem('meioDeContatoId', this.erros, 'field')">
                    {{ verificarItemArray('meioDeContatoId', this.erros, 'field').message }}
                </p>
            </div>

            <div class="formulario-item">
                <label for="critico" class="formulario-item-label">Crítico</label>
                <multiselect :class="{ 'invalid-field-multiselect': verificarSeHaItem('criticoId', this.erros, 'field') }"
                    :disabled="perfilPainel == 4" v-model="dadosDropdowns.criticoId" label="descricao" track-by="id"
                    :options="opcoes.critico" :searchable="false" :multiple="false" placeholder="Selecione"
                    select-label="Selecionar" deselect-label="Remover" selected-label="Selecionado" :allow-empty="true" />
                <p class="input-erro-mensagem" v-if="erros.length && verificarSeHaItem('criticoId', this.erros, 'field')">
                    {{ verificarItemArray('criticoId', this.erros, 'field').message }}
                </p>
            </div>
        </div>

        <div v-if="editar && perfilPainel != 4" class="container-btns">
            <button type="button" class="btn btn-laranja" @click="chamaModal()">Salvar</button>
        </div>

        <tabs v-if="editar">
            <tab v-if="perfilPainel != 4" name="Internas">
                <Tabela v-if="msgInternas.length" :colunas="colunas" :linhas="msgInternas" :paginacao="true"
                    :pagina="pagina" :totalPaginas="totalPaginas" :funcProximaPag="proximaPag"
                    :funcAnteriorPag="anteriorPag" />
                <p class="sem-dados" v-else>Nenhuma mensagem cadastrada.</p>
            </tab>

            <tab name="Externas" :selected="true">
                <Tabela v-if="msgExternas.length" :colunas="colunas" :linhas="msgExternas" :paginacao="true"
                    :pagina="pagina" :totalPaginas="totalPaginas" :funcProximaPag="proximaPag"
                    :funcAnteriorPag="anteriorPag" />
                <p class="sem-dados" v-else>Nenhuma mensagem cadastrada.</p>
            </tab>
        </tabs>

        <div class="formulario-item">
            <label class="formulario-item-label">Coloque a resposta abaixo - {{ 2000 - dados.mensagem.length +
                ' caracteres restantes'
            }}</label>
            <textarea class="campos-textarea" v-model="dados.mensagem" name="mensagem" maxlength="2000"
                placeholder="Digite aqui..." />
        </div>

        <div class="container-options">
            <div class="container-options__upload" v-if="dados.selectedFile">
                <div class="boxDoc">
                    <img src="@/assets/images/icones/img-anexo.svg" alt="imagem do anexo" className="m-3" />
                    <div class="conteudoAnexo">
                        <p class="nome">{{ dados.selectedFile.name }}</p>
                        <p class="tamanho">{{ bytesToMegaBytes(dados.selectedFile.size) }} Mb</p>
                    </div>
                    <img class="excluir" src="@/assets/images/icones/trash-black.svg" alt="excluir anexo"
                        @click="apagarAnexo" />
                </div>
            </div>

            <div class="container-options__inputs">
                <input id="input-pdf" @change="newFile" type="file" name="anexos" />
                <label for="input-pdf" class="pdf-btn">Adicionar Anexo</label>
                <GrpRadio :options="tiposUsuario" v-model="dados.tipoUsuario" />
            </div>
        </div>

        <div class="container-btns">
            <button type="button" class="btn btn-cancelar" @click="voltar">Voltar</button>
            <!-- Req para enviar mensagem -->
            <button v-if="editar" type="button" class="btn btn-laranja" @click="enviarMensagem">Enviar
            </button>
            <!-- Req para criar ocorrência -->
            <button v-if="!editar" type="button" class="btn btn-laranja" @click="cadastrarOcorrencia">Cadastrar
            </button>
        </div>
    </div>
</template>

<script>
import store from "@/store";
import GrpRadio from '@/components/grpRadio/GrpRadio.vue';
import multiselect from "vue-multiselect";
import { listarSacStatus, listarSacAssunto, listarSacMeioDeContato } from '@/services/sac'
import opcoes from "@/utils/json/opcaoSelect"
import Tab from "@/components/estrutura/tabs/_Tab";
import Tabs from "@/components/estrutura/tabs/_Tabs";
import Tabela from "@/components/Tabela/Tabela.vue";
import { functions, objects } from "@/assets/js";
import {
    cadastrarMensagemOcorrencia,
    listarSacMensagemDestinatario,
    listarDetalhesOcorrenciaId,
    atualizarDetalhesOcorrencia,
    cadastrarOcorrencia
} from '@/services/sac'

export default {

    name: "FormularioOcorrencia",

    components: {
        multiselect,
        GrpRadio,
        Tabs,
        Tab,
        Tabela,
    },

    props: {
        editar: {
            type: Boolean,
            required: true
        }
    },

    data() {
        return {
            perfilPainel: parseInt(localStorage.getItem('perfil')),
            colunas: [
                { key: "dataHora", titulo: 'Data', tamanho: 'medio-130', link: false },
                { key: "nomeUsuario", titulo: 'Usuário', tamanho: 'medio-240-visivel', link: false },
                { key: "mensagem", titulo: 'Mensagem', tamanho: 'grande-visivel', link: false },
                { key: "anexos", titulo: 'Arquivo', tamanho: 'medio-130', link: false },
                { key: "sacMensagemDestinatarioId", titulo: 'Destinatário', tamanho: 'medio-130', link: false },
            ],
            msgErroAlert: '',
            dadosDropdowns: {
                statusId: [],
                assuntoId: [],
                meioDeContatoId: [],
                criticoId: []
            },
            dados: {
                selectedFile: '',
                mensagem: '',
                tipoUsuario: '',
            },
            erros: [],
            objReferencia: {},
            tiposUsuario: [],
            msgInternas: [],
            msgExternas: [],
            opcoes: opcoes,
            status: [],
            assunto: [],
            meioDeContato: [],
            pagina: 1,
            qtdPagina: 10,
            totalPaginas: 0
        }
    },

    async mounted() {
        await Promise.all([
            listarSacMensagemDestinatario().then(resp => {
                this.tiposUsuario = resp.data.filter(destinatario => this.verificarOpcoesDestinatarios(destinatario.id))
            }),
            listarSacAssunto().then(resp => {
                this.assunto = resp.data
            }),
            listarSacMeioDeContato().then(resp => {
                this.meioDeContato = resp.data
            }),
            listarSacStatus().then(resp => {
                this.status = resp.data
            }),
        ])
        this.popularDadosEditar()
    },

    methods: {
        verificarSeHaItem: functions.verificarSeHaItem,
        verificarItemArray: functions.verificarItemArray,
        verificarOpcoesDestinatarios(destinatario) {
            const usuarioParticipante = this.perfilPainel === 4
            return usuarioParticipante ? destinatario == 4 : destinatario !== 4
        },
        popularDadosEditar() {
            if (this.editar) {
                listarDetalhesOcorrenciaId(this.$route.params.ocorrenciaId, this.pagina, this.qtdPagina).then(resp => {
                    this.dadosDropdowns = {
                        statusId: this.verificarItemArray(resp.data.lista.status, this.status, 'id'),
                        assuntoId: this.verificarItemArray(resp.data.lista.sacAssuntoId, this.assunto, 'id'),
                        meioDeContatoId: this.verificarItemArray(resp.data.lista.meioDeContato, this.meioDeContato, 'id'),
                        criticoId: this.verificarItemArray(resp.data.lista.critico, this.opcoes.critico, 'value')
                    }
                    this.objReferencia = {
                        sacStatusId: resp.data.lista.status,
                        sacAssuntoId: resp.data.lista.sacAssuntoId,
                        sacOcorrenciaMeioDeContatoId: resp.data.lista.meioDeContato,
                        critico: resp.data.lista.critico
                    }
                    this.separaMensagens(resp.data.lista.mensagens)
                    this.totalPaginas = resp.data.paginacao.totalPaginas
                })
            }
        },
        separaMensagens(mensagens) {
            this.msgInternas = mensagens.filter((item) => item.sacMensagemDestinatarioId === 3)
            this.msgExternas = mensagens.filter((item) => item.sacMensagemDestinatarioId !== 3)
        },
        proximaPag() {
            this.pagina++;
            this.popularDadosEditar();
        },

        anteriorPag() {
            this.pagina--;
            this.popularDadosEditar();
        },
        atualizarOcorrencia() {
            const dadosAtualizados = {
                sacStatusId: this.dadosDropdowns.statusId.id,
                sacAssuntoId: this.dadosDropdowns.assuntoId.id,
                sacOcorrenciaMeioDeContatoId: this.dadosDropdowns.meioDeContatoId.id,
                critico: this.dadosDropdowns.criticoId.value
            }
            atualizarDetalhesOcorrencia(this.$route.params.ocorrenciaId, dadosAtualizados).then(() => {
                this.popularDadosEditar()
            })
        },
        chamaModal() {
            if (!this.formValid()) {
                return;
            }
            else if (objects.comparacaoObjForm(this.objReferencia, this.formDataAtt())) {
                store.commit("SET_MODAL", {
                    ativado: true,
                    mensagem: 'Não houve nenhuma mudança nos dados',
                    tipo: "erro",
                })
            } else {
                const config = {
                    ativado: true,
                    tipo: "confirmacao",
                    mensagem: `Tem certeza que deseja atualizar os dados da ocorrência ?`,
                    func: this.atualizarOcorrencia
                }
                store.commit("SET_MODAL_MENSAGEM", config);
            }
        },
        formValid() {
            this.erros = []

            if (!functions.verificarDadoValido(this.dadosDropdowns.statusId)) {
                this.erros.push({ field: "statusId", message: "Status é obrigatório" });
            }

            if (!functions.verificarDadoValido(this.dadosDropdowns.assuntoId)) {
                this.erros.push({ field: "assuntoId", message: "Assunto é obrigatório" });
            }

            if (!functions.verificarDadoValido(this.dadosDropdowns.meioDeContatoId)) {
                this.erros.push({ field: "meioDeContatoId", message: "Meio de contato é obrigatório" });
            }

            if (!functions.verificarDadoValido(this.dadosDropdowns.criticoId)) {
                this.erros.push({ field: "criticoId", message: "Crítico é obrigatório" });
            }

            if (this.erros.length) {
                return false;
            }

            return true;
        },
        formDataAtt() {
            const data = new FormData();

            data.append('sacStatusId', this.dadosDropdowns.statusId.id);
            data.append('sacAssuntoId', this.dadosDropdowns.assuntoId.id);
            data.append('sacOcorrenciaMeioDeContatoId', this.dadosDropdowns.meioDeContatoId.id);
            data.append('critico', this.dadosDropdowns.criticoId.value);

            return data;
        },
        formData() {
            const data = new FormData();

            data.append('ocorrenciaId', parseInt(this.$route.params.ocorrenciaId));
            data.append('mensagem', this.dados.mensagem);
            this.dados.selectedFile && data.append('arquivos', this.dados.selectedFile, this.dados.selectedFile.name);
            data.append('sacDestinatarioId', this.dados.tipoUsuario);

            return data;
        },
        enviarMensagem() {
            if (this.identificarErrosEnvioMsg()) {
                return store.commit("SET_MODAL", {
                    ativado: true,
                    mensagem: this.msgErroAlert,
                    tipo: "erro",
                })
            }
            cadastrarMensagemOcorrencia(this.formData()).then(() => {
                this.popularDadosEditar()
                this.zerarDados()
                this.popularDestinatarios()
            })
        },
        zerarDados() {
            this.dados = {
                selectedFile: '',
                mensagem: '',
                tipoUsuario: '',
            }
            let inputs = document.querySelectorAll('input[type="radio"]');
            for (let i = 0, l = inputs.length; i < l; i++) {
                inputs[i].checked = false;
            }
        },
        identificarErrosEnvioMsg() {
            this.dados.tipoUsuario === '' && (this.msgErroAlert = 'Deverá escolher um tipo de destinatário')
            if (!(this.dados.mensagem.length !== 0 || this.dados.selectedFile !== '')) this.msgErroAlert = 'Deverá possuir texto ou arquivo para enviar uma mensagem'

            return !(this.dados.mensagem.length !== 0 || this.dados.selectedFile !== '') || this.dados.tipoUsuario == ''
        },
        voltar() {
            this.$router.back(-1)
        },
        verificarTipoImg(tipo) {
            let tipoPermitido = false;
            const arrTipoImg = ['png', 'jpg', 'jpeg']
            for (const key in arrTipoImg) {
                const tipoImg = arrTipoImg[key];
                if (tipo.search(tipoImg) !== -1) {
                    return tipoPermitido = true;
                }
            }
            return tipoPermitido;
        },
        verificarTamanhoFile(tamanho) {
            let tamanhoPermitido = true
            const tamanhoMax = 20971520
            if (tamanho > tamanhoMax) {
                return tamanhoPermitido = false
            }
            return tamanhoPermitido
        },
        newFile(e) {
            const { type, size } = e.target.files[0]
            if (!this.verificarTipoImg(type)) {
                store.commit("SET_MODAL", {
                    ativado: true,
                    mensagem: 'Apenas é permitido anexar imagens tipo png, jpeg e jpg na mensagem',
                    tipo: "erro",
                })
            } else if (!this.verificarTamanhoFile(size)) {
                store.commit("SET_MODAL", {
                    ativado: true,
                    mensagem: 'A imagem ultrapassa o tamanho permitido que é 20Mb',
                    tipo: "erro",
                })
            } else {
                this.dados.selectedFile = e.target.files[0]
            }
        },
        apagarAnexo() {
            this.dados.selectedFile = '';
        },
        cadastrarOcorrencia() {
            if (this.identificarErrosEnvioMsg()) {
                return store.commit("SET_MODAL", {
                    ativado: true,
                    mensagem: this.msgErroAlert,
                    tipo: "erro",
                })
            } else if (!this.formValid()) {
                return;
            } else {

                const dados = new FormData();
                dados.append('idUsuario', parseInt(this.$route.params.id));
                dados.append('sacAssuntoId', this.dadosDropdowns.assuntoId.id);
                dados.append('sacAssuntoDescricao', this.dadosDropdowns.assuntoId.descricao);
                dados.append('mensagem', this.dados.mensagem);
                dados.append('sacMensagemDestinatarioId', parseInt(this.dados.tipoUsuario));
                dados.append('sacOcorrenciaMeioDeContatoId', this.dadosDropdowns.meioDeContatoId.id);
                dados.append('critico', this.dadosDropdowns.criticoId.value);
                dados.append('sacStatusId', this.dadosDropdowns.statusId.id);
                this.dados.selectedFile && dados.append('arquivos', this.dados.selectedFile, this.dados.selectedFile.name);

                // const dados = {
                //     idUsuario: parseInt(this.$route.params.id),
                //     sacAssuntoId: this.dadosDropdowns.assuntoId.id,
                //     sacAssuntoDescricao: this.dadosDropdowns.assuntoId.descricao,
                //     mensagem: this.dados.mensagem,
                //     sacMensagemDestinatarioId: parseInt(this.dados.tipoUsuario),
                //     sacOcorrenciaMeioDeContatoId: this.dadosDropdowns.meioDeContatoId.id,
                //     critico: this.dadosDropdowns.criticoId.value,
                //     sacStatusId: this.dadosDropdowns.statusId.id
                // }

                cadastrarOcorrencia(dados).then(() => {
                    this.voltar()
                })
            }
        },
        bytesToMegaBytes(bytes) {
            return (bytes / (1024 * 1024)).toFixed(2);
        }
    }

};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"/>

<style lang="scss" scoped>
.sem-dados {
    padding: 0 10px;
}

.pdf-btn {
    width: 16rem;
}

.container-options {
    display: flex;
    flex-direction: column;
    gap: 15px;
    margin: 0 10px;

    &__upload {
        .boxDoc {
            background-color: rgb(221, 230, 240);
            display: flex;
            align-items: center;
            width: 16rem;
            border: 1px solid rgb(172, 172, 172);
            border-radius: 8px;
            justify-content: space-between;
            padding: 6px 16px;
            gap: 16px;
            text-align: center;

            .nome {
                width: 184px;
                height: 1.1rem;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }

            .excluir {
                cursor: pointer;
            }

            img {
                height: 20px;
            }
        }
    }

    &__inputs {
        display: flex;
        align-items: center;
        gap: 24px;

        input {
            display: none;
        }
    }
}
</style>